import React from "react"
import infoEmail from "../../../images/info-email.png"
import Button from "../../../components/button"
import { Link } from "gatsby"
import './styles.css';
const IntroductionOfAiCreation = () => {
  const styles = {
    heading: {
      marginBottom: "10px",
      marginTop: "30px",
    },
    button: {
      cursor: "pointer",
      color: "white",
      textAlign: "center",
      fontWeight: 700,
      backgroundColor: "#092c4c",
      fontSize: "18px",
      padding: "15px 35px",
      borderRadius: "30px",
      display: "block",
      marginTop: "20px",
    },
    divsmall: {
      marginBottom: "15px",
    },
    div: {
      marginBottom: "35px",
    },
  }

  return (
    <div>
       <h4 style={styles.heading}>Aotearoa New Zealand’s only NCEA + AI + Business Technology + Micro-credential </h4>
       <h6 style={styles.heading}>Purpose</h6>
      <div style={styles.div}>
      The purpose of the <strong>“Introduction to AI Technology and AI Assistant Creation (Micro-credential)”</strong> is to provide learners with foundational knowledge and practical skills in artificial intelligence (AI) technology, with a specific focus on designing and implementing AI assistants that enhance business administration processes.
      <br/><br/>
      This micro-credential is designed for individuals at Level 3 who are interested in understanding the basics of AI and its applications in real-world business contexts, including tasks such as records management, visitor management, communication, and scheduling.
      </div>
      <div style={styles.div}>
        <ul>
        <li><strong>Students gain 15 credits at Level 3 for their NCEA</strong></li>
        <li><strong>Students achieve Unit Standard “Provide business administration support using business technology”</strong></li>
        <li><strong>Students are awarded the NZQA Introduction to AI Technology and AI Assistant Creation (Micro-credential)</strong></li>
        </ul>
      </div>
      <div style={styles.div}>
      Learners will engage in a project-based learning approach, progressively designing, building, and deploying AI assistants while integrating business technology skills as outlined in NZQA unit standard 29024. The course emphasises the practical application of AI in supporting business operations, ensuring that learners gain relevant skills that meet both AI and business administration requirements.<br/><br/>Upon completion, learners will have a solid foundation in AI technology and business administration, equipping them for further study or entry-level roles in fields related to AI, computing, and business support.
      </div>
      <h6 style={styles.heading}>Requirements for Teaching</h6>
      <div style={styles.div}>To offer this exclusive, world-first, exciting AI+NZQA+NCEA course each teacher must have completed the <Link to="../advanced-applications-of-ai-in-education">Advanced Applications of AI in Education.</Link><br/><br/>
The Advanced NZQA micro-credential is offered four times per year as an online block course (6 hours) plus virtual support & moderation meetings available twice per term (1 hour each).
</div>
      <h6 style={styles.heading}>Completion</h6>
      <div style={styles.div}>To be awarded the <strong>“Introduction to AI Technology and AI Assistant Creation (Micro-credential),”</strong> learners must successfully complete all five components of the micro-credential. Each component is designed to build upon the previous one, ensuring that learners develop a comprehensive understanding and practical skills in AI technology, AI assistant creation, and business administration tasks as outlined in NZQA unit standard 29024.<br/><br/>
      Completion is based on achieving competency in each component, as assessed through the methods outlined in the Assessment Methods section. Competency includes demonstrating the required skills and knowledge for both the AI-specific learning outcomes and the integrated business administration tasks of the unit standard.<br/><br/>
      Only upon successful completion of all components, and thus meeting the performance criteria of unit standard 29024, will the micro-credential be awarded.
</div>
      <div className="contact-content" style={{width:"100%",background:"#083051",paddingTop:"30px",paddingBottom:"30px"}}>
              <h3 className="contact-heading">We'd love to hear from you</h3>
              <span style={{ marginBottom: "10px" }}>
                You can contact us at
              </span>
              <img
                src={infoEmail}
                style={{ marginBottom: "20px"}}
                alt="email address" className="responsive-img"
              />
              <span style={{ marginBottom: "20px" }}>
                and one of our team will be in touch
              </span>
              <a href="mailto:contact@summitinstitute.ac.nz?subject=Mail from Summit Institute">
                <Button colorSchema="sec">Email us</Button>
              </a>
            </div>
    </div>
  )
}

export default IntroductionOfAiCreation
